import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import googleSigninSVG from './assets/google_signin.svg';

function Copyright() {
    return (
        <p variant="body2" style={{textAlign: "center"}}>
            {'©'}
            {new Date().getFullYear()}
            {' '}
            The Beach Volleyball Net
        </p>
    );
}

function Login() {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    const navigate = useNavigate();

    const handleGoogleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            console.log('Logged in with Google:', user);
            
            navigate('/');
        } catch (error) {
            console.error('Error logging in with Google:', error);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <h1>Log in</h1>
            <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
              {/* <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              {/* <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button> */}

                {/* Sign in with Google button */}
                <img src={googleSigninSVG} onClick={handleGoogleLogin} alt="Sign in with Google" />

                <Grid container>
                    <Grid item>
                    <Link href="/register" variant="body2">
                        {"Don't have an account? Sign Up"}
                    </Link>
                    </Grid>
                </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
    );
}

// function Login() {
//     const auth = getAuth();
//     const provider = new GoogleAuthProvider();
//     const navigate = useNavigate();

//     const handleGoogleLogin = async () => {
//         try {
//             const result = await signInWithPopup(auth, provider);
//             const user = result.user;
//             console.log('Logged in with Google:', user);
            
//             navigate('/');
//         } catch (error) {
//             console.error('Error logging in with Google:', error);
//         }
//     };

//     return (
//         <div>
//             <h1>Login</h1>
//             <button onClick={handleGoogleLogin}>Login with Google</button>
//         </div>
//     );
// }

export default Login;