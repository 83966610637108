import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import './App.css';
import Navbar from './components/Navbar'
import MapComponent from './Map'

function Home() {
    const [user, setUser] = useState(null);
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
        });

        return () => unsubscribe();
    }, []);

    return (
        <div>
            <Navbar user={user} />
            <MapComponent />
        </div>
    )
}

export default Home