import { APIProvider, Map, AdvancedMarker, Pin, InfoWindow } from '@vis.gl/react-google-maps';
import { useState, useEffect } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import './Map.css';

const containerStyle = {
  width: '100vw',
  height: '100vh'
};

function MapComponent() {
  const [data, setData] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  
  // Initialize center to a default location
  const [center, setCenter] = useState({
    lat: -123.132220,
    lng: 49.257662
  });

  useEffect(() => {
    // Try to update center to the user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      }, () => {
        // Do nothing if the user denies location access
      });
    }
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, 'bv_courts'));
      const data = querySnapshot.docs.map(doc => doc.data());
      setData(data);
    };

    fetchData();
  }, []);

  return (
    <APIProvider apiKey={'AIzaSyCSryauroU9gq4fotfBgzpTtegnfg89OcM'}>
      <Map 
        mapId={'6b3a81693a187018'}
        defaultCenter={center} 
        defaultZoom={13} 
        style={containerStyle}
        disableDefaultUI={true} 
        zoomControl='true'
      >
        {/* Marker for user's current location */}
        {userLocation && (
          <AdvancedMarker position={userLocation} />
        )}

        {/* Beach volleyball court pins */}
        {data.map((item, index) => (
          <AdvancedMarker 
            key={index} 
            position={{ lat: item.location.latitude, lng: item.location.longitude }}
            onClick={() => setSelectedMarker(item)}
          >
              <Pin
              background={'#0f9d58'}
              borderColor={'#006425'}
              glyphColor={'#fff'}
            />
          </AdvancedMarker>
        ))}
        
        {/* InfoWindow for selected marker */}
        {selectedMarker && (
          <InfoWindow 
            position={{ lat: selectedMarker.location.latitude, lng: selectedMarker.location.longitude }}
            onCloseClick={() => setSelectedMarker(null)}
            maxWidth={320}
            className='bv_court-item-popup'
            disableAutoPan={false}
          >
            <div className='bv_court-item-contents'>
              {selectedMarker.image_url && (
                <img 
                  src={selectedMarker.image_url} 
                  className='bv_court-image' 
                  alt={selectedMarker.name}
                  style={{
                    width: '100%',
                    height: '180px',
                    objectFit: 'cover'
                  }}
                />
              )}
              <h3 className='bv_court-name'>{selectedMarker.name}</h3>
              <p className='bv_court-short_description'>{selectedMarker.short_description}</p>
            </div>
          </InfoWindow>
        )}
      </Map>
    </APIProvider>
  )
}

export default MapComponent;