import * as React from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
import logoSVG from '../assets/logo.svg';
import './Navbar.css';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';

const logoStyle = {
  width: '180px',
  height: 'auto',
  marginLeft: '16px',
  cursor: 'pointer'
};

function Navbar({ user }) {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    // Handle the search submit event here. For example, you could call a function that searches Google Maps.
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth="md">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor: 'rgba(255, 255, 255, 0.75)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
              }}
            >
              <img src={logoSVG} className="app-logo" alt="logo" style={logoStyle} />
              {/* <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <MenuItem
                  onClick={() => scrollToSection('features')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <div>
                    Features
                  </div>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('testimonials')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary">
                    Testimonials
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection('highlights')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary">
                    Highlights
                  </Typography>
                </MenuItem>
              </Box> */}
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 1,
                alignItems: 'center',
              }}
            >
              {/* Add the search bar to your JSX: */}
              <form 
                onSubmit={handleSearchSubmit} 
                style={{ display: 'flex', gap: '4px'}}
              >
                <TextField
                  hiddenLabel
                  value={search}
                  variant='filled'
                  onChange={handleSearchChange}
                  placeholder='Search map'
                  autoComplete='off'
                  sx={{
                    // bgcolor: 'background.paper',
                    borderRadius: '24px',
                    // border: 'none',
                    '& .MuiInputBase-input': {
                      padding: '8px 12px',
                    },
                    '& .MuiInputLabel-root': {
                      top: '-6px',
                    },
                    '&:hover': {
                      bgcolor: 'background.default', // Change as needed
                    },
                    '& .Mui-focused.Mui-focused': {
                      bgcolor: '#FFF', // Change as needed
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      padding: '0 8px',
                      borderRadius: '24px',
                    }
                  }}
                />
                <Button type="submit" sx={{minWidth: 'unset', borderRadius: '24px'}}>
                  <SearchIcon />
                </Button>
              </form>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              {/* <Button
                color="primary"
                variant="text"
                size="small"
                component="a"
                href="/material-ui/getting-started/templates/sign-in/"
                target="_blank"
              >
                Login
              </Button> */}
              {user ? (
                <Link to="/account">
                  <Avatar>{user.email[0]}</Avatar>
                </Link>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  component="a"
                  href="/register"
                >
                  Signup
                </Button>
              )}
            </Box>
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  >
                  </Box>
                  {/* <MenuItem onClick={() => scrollToSection('features')}>
                    Features
                  </MenuItem>
                  <Divider /> */}
                  <MenuItem>
                  {user ? (
                    <Link to="/account">
                      <Avatar>{user.email[0]}</Avatar>
                    </Link>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      component="a"
                      href="/register"
                      sx={{ width: '100%' }}
                    >
                      Signup
                    </Button>
                  )}
                  </MenuItem>
                  {/* <MenuItem>
                    <Button
                      color="primary"
                      variant="outlined"
                      component="a"
                      href="/material-ui/getting-started/templates/sign-in/"
                      target="_blank"
                      sx={{ width: '100%' }}
                    >
                      Login
                    </Button>
                  </MenuItem> */}
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

// Navbar.propTypes = {
//   mode: PropTypes.string.isRequired
// };

export default Navbar;