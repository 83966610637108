// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDIWU7iIlhw-W5DDBwYNOUAsr8r3ecsCJY",
    authDomain: "the-beach-volleyball-net.firebaseapp.com",
    projectId: "the-beach-volleyball-net",
    storageBucket: "the-beach-volleyball-net.appspot.com",
    messagingSenderId: "895059961697",
    appId: "1:895059961697:web:f6db97b5c9eb06776cc720",
    measurementId: "G-RZZSFB08YT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };