import { useLocation, useNavigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";

function Account() {
    const auth = getAuth();
    const location = useLocation();
    const name = location.state ? location.state.name : 'Default Name';
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
        await auth.signOut();

        navigate('/');
        } catch (error) {
        console.error('Error logging out:', error);
        }
    };

    return (
        <div>
        <h1>Account</h1>
        <input type="text" value={name} readOnly />
        {/* Add log out option */}
            <button onClick={handleLogout}>Log out</button>
        </div>
    );
}

export default Account;